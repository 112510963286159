//router.js
import { createRouter, createWebHistory } from 'vue-router';
import { store } from './store';
import FileUpload from './components/FileUpload.vue';
import LoginMod from './components/Login_mod.vue';
import Admin from './components/Admin_mod.vue';
import Democracy from './components/Democracy_mod.vue';
import MultiTools from './components/MultiTools.vue';
import RoleBasedModule from './components/RoleBasedModule.vue';

const routes = [
  { path: '/', component: LoginMod },
  {
    path: '/home',
    component: RoleBasedModule,
    meta: { requiresAuth: true },
    children: [
      { path: 'file-upload', component: FileUpload, meta: { requiresAuth: true, allowedRoles: ['admin', 'gold_digger'] } },
      { path: 'admin', component: Admin, meta: { requiresAuth: true, allowedRoles: ['admin'] } },
      { path: 'democracy', component: Democracy, meta: { requiresAuth: true, allowedRoles: ['admin', 'meps'] } },
      { path: 'multitools', component: MultiTools, meta: { requiresAuth: true, allowedRoles: ['admin', 'tools'] } }
    ]
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.isLoggedIn || !store.userInfo.token) {
      next('/'); // Redirect to login
    } else {
      const allowedRoles = to.meta.allowedRoles;
      const userRole = store.userInfo.userRole;

      if (allowedRoles && !allowedRoles.includes(userRole)) {
        store.errorMessage.visible = true;
        store.errorMessage.message = "Vous n'êtes pas autorisé à accéder à cette page.";
        next('/'); // Redirect to login if role not allowed
      } else {
        next(); // Allow access
      }
    }
  } else {
    next(); // Public route access
  }
});

export default router;
