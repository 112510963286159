<template>
  <div class="role-based-module">
    <!-- Vérifiez que userRole est bien transmis -->

    <!-- Si l'utilisateur a le rôle 'user' -->
    <div v-if="userRole === 'user'" class="image-container">
      <div class="overlay-text">No apps for you</div>
    </div>

    <!-- Module 'Diggin' it !' pour les rôles 'admin' et 'gold_digger' -->
    <div v-if="userRole === 'admin' || userRole === 'gold_digger'">
      <div class="image-button-container">
        <router-link
          to="/home/file-upload"
          class="image-button"
          :style="{ backgroundImage: 'url(' + goldDiggerImage + ')' }"
        >
          <span class="button-text">Diggin' it !</span>
        </router-link>
        <router-link to="/home/file-upload" class="sub-text">Traitement des fichiers de ventes d'or</router-link>
      </div>
    </div>

    <!-- Module 'Admin' pour le rôle 'admin' -->
    <div v-if="userRole === 'admin'">
      <div class="image-button-container">
        <router-link
          to="/home/admin"
          class="image-button"
          :style="{ backgroundImage: 'url(' + adminImage + ')' }"
        >
          <span class="button-text">Admin</span>
        </router-link>
        <router-link to="/home/admin" class="sub-text">Interface d'administration</router-link>
      </div>
    </div>

    <!-- Module 'Démocratie' pour les rôles 'admin' et 'meps' -->
    <div v-if="userRole === 'admin' || userRole === 'meps'">
      <div class="image-button-container">
        <router-link
          to="/home/democracy"
          class="image-button"
          :style="{ backgroundImage: 'url(' + democracyImage + ')' }"
        >
          <span class="button-text">Démocratie</span>
        </router-link>
        <router-link to="/home/democracy" class="sub-text">Données d'intérêt démocratique</router-link>
      </div>
    </div>

    <!-- Module 'Vrac' pour les rôles 'admin' et 'tools' -->
    <div v-if="userRole === 'admin' || userRole === 'tools'">
      <div class="image-button-container">
        <router-link
          to="/home/multitools"
          class="image-button"
          :style="{ backgroundImage: 'url(' + multiTools + ')' }"
        >
          <span class="button-text">Vrac</span>
        </router-link>
        <router-link to="/home/multitools" class="sub-text">Outils en vrac</router-link>
      </div>
    </div>

    <!-- Add this condition to only render router-view if a component is available -->
    <router-view v-if="$route.matched.length > 1"></router-view>

  </div>
</template>

<script>
import { store } from "../store";

export default {
  name: "RoleBasedModule",
  props: {
    userRole: String,
  },
  data() {
    return {
      goldDiggerImage: require("../../images/gold_digger.webp"),
      adminImage: require("../../images/admin.webp"),
      democracyImage: require("../../images/democracy.webp"),
      noAppImage: require("../../images/noapp.webp"),
      multiTools: require("../../images/multitools.webp"),
    };
  },
  watch: {
    userRole(newVal) {
      console.log("userRole changed:", newVal);
    }
  },
  methods: {
    async validateToken() {
      try {
        const response = await fetch(`${this.$apiUrl}/validate_token`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${store.userInfo.token}`,
          },
        });

        if (!response.ok) {
          // Invalid token, logout user
          store.isLoggedIn = false;
          store.userInfo.token = '';
          store.userInfo.userName = '';
          store.userInfo.userRole = '';
          localStorage.removeItem("token");
          this.$emit('logout'); // Notify parent about logout
          throw new Error('Token invalide');
        }

        // Optionally handle the successful response here
        const data = await response.json();
        console.log("Token valide:", data);

      } catch (error) {
        console.error("Erreur lors de la validation du token :", error);
        // Logout the user in case of error
        store.isLoggedIn = false;
        store.userInfo.token = '';
        store.userInfo.userName = '';
        store.userInfo.userRole = '';
        localStorage.removeItem("token");
        this.$emit('logout'); // Notify parent about logout
      }
    },
  },
  mounted() {
    console.log("Mounted RoleBasedModule. UserRole:", this.userRole); // Vérifier la valeur de userRole
    store.pucksMessage = "Choisis ton poison, " + store.userInfo.userName;

    // Validate the token when the component is mounted
    this.validateToken();
  },
};
</script>

<style scoped>
</style>
