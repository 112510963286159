//Login_mod.vue
<template>
  <div class="login-container d-flex flex-column justify-content-center align-items-center">
    <!-- Formulaire de connexion si l'utilisateur n'est pas authentifié -->
    <form v-if="!isAuthenticated" @submit.prevent="login_mod" class="login-form">
      <!-- Champs du formulaire -->
      <div class="form-group-login">
        <input type="text" class="form-control" v-model="username" placeholder="Nom d'utilisateur" required />
        <input type="password" class="form-control" v-model="password" placeholder="Mot de passe" required />
      </div>
      <div class="mt-auto w-100 text-center">
        <button type="submit" class="btn btn-primary">Connexion</button>
      </div>
    </form>

    <!-- Affichage du message d'erreur de connexion si présent -->
    <div v-if="loginError" class="alert alert-danger">{{ loginError }}</div>
  </div>
</template>

<script>
import axiosInstance from "../axiosConfig";
import { store } from "../store";

export default {
  setup() {
    return { store };
  },
  components: {
  },
  data() {
    return {
      username: "",
      password: "",
      loginError: "",
      isAuthenticated: false, // Nouvelle propriété pour suivre l'état d'authentification
    };
  },
  methods: {
    login_mod() {
      let formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("client_id", "");
      formData.append("client_secret", "");
      axiosInstance
        .post(`${this.$apiUrl}/token`, formData)
        .then((response) => {
          const token = response.data.access_token;
          const userName = response.data.name;
          const userRole = response.data.role;
          console.log("Login response received. Token:", token, "User:", userName, "Role:", userRole);

          localStorage.setItem("token", token);
          localStorage.setItem("name", userName);
          localStorage.setItem("role", userRole);
          this.isAuthenticated = true; // Mettre à jour l'état d'authentification
          this.$emit("login-success", token, userName, userRole);
        })
        .catch((error) => {
          console.error("Error:", error);
          this.loginError =
            "Échec de la connexion. Veuillez vérifier vos identifiants et réessayer.";
          store.pucksMessage = "🚫 Vous n'avez pas dit le mot magique 🚫";  
          this.$emit("login-error");
        });
    },
  },
  mounted() {
    // Vérifier si le jeton existe dans le localStorage
    const token = localStorage.getItem("token");
    if (token) {
      this.isAuthenticated = true;
      // Vous pouvez également émettre un événement ou rediriger l'utilisateur
      const userName = localStorage.getItem("name");
      const userRole = localStorage.getItem("role");
      this.$emit("login-success", token, userName, userRole);
    } else {
      this.isAuthenticated = false;
    }
    store.pucksMessage = "Qui es-tu ?";
  },
};
</script>


<style scoped>
.login-container {
  background-color: rgba(0, 0, 0, 0.8); /* Fond sombre semi-transparent */
  border-radius: 10px;
  padding: 20px;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; /* Assurez-vous que le conteneur a une hauteur définie */
}

.login-form .form-group-login {
  margin-bottom: 15px;
  height: 100%; /* Assurez-vous que le formulaire remplit le conteneur */
}

.form-control {
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Fond clair semi-transparent pour les champs */
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.7);
}

/* Ajouter un style pour les messages d'erreur */
.error {
  color: #dc3545; /* Utilisez une couleur rouge pour les erreurs */
  text-shadow: 0 0 3px #000;
}
</style>

